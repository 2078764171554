<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        权限说明
      </h1>
      <div class="text notitle">
        为了保证您正常的游戏体验，将向您申请获取以下权限：<br><br>
        储存权限：下载和安装游戏更新内容, 拒绝授权将可能无法正常进行游戏<br><br>
        电话权限：需要申请电话权限以获取设备标识(AndroidID、mac)，生成帐号、保存和恢复游戏数据<br><br>
        后台运行权限：允许程序在手机屏幕关闭后后台进程仍然运行，防止游戏进程断开，返回游戏时无需重启游戏<br><br>
        网络状态权限：允许获取当前WiFi接入的状态以及WLAN热点的信息，确保游戏在联网情况下才可正常运行<br><br>
        获取应用安装列表权限：收集应用程序性能、崩溃等情况，为了预防恶意程序以及安全运营所必需 （注：如您希望使用游戏中的第三方功能/服务，为确保您能正常使用相应功能/服务，该第三方可能会主动核实并向我们发送您的应用安装列表信息，但您理解我们不会收集和使用被动接收到的该等信息）
      </div>
      <h1 :dir="dir">
        以下是华为渠道集成所需权限
      </h1>
      <div class="text scrollable">
        允许应用程序打开系统窗口，显示其他应用程序的顶层<br><br>
        允许应用程序开机自动运行<br><br>
        允许应用程序收到广播后快速收到下一个广播<br><br>
        允许访问振动设备<br><br>
        允许应用程序读取系统底层日志<br><br>
        允许应用程序读取或写入系统设置<br><br>
        允许应用程序挂载、卸载外部文件系统<br><br>
        允许应用程序不显示通知下载<br><br>
        允许应用程序禁用键盘锁<br><br>
        允许应用程序获取当前或最近运行的应用<br><br>
        允许应用程序注册和接收C2DM Push消息<br><br>
        com.asus.msa.SupplementaryDID.ACCESS<br><br>
        com.google.android.finsky.permission.BIND_GET_INSTALL_REFERRER_SERVICE<br><br>
        android.permission.ACCESS_DOWNLOAD_MANAGER<br><br>
        android.webkit.permission.PLUGIN<br><br>
        com.huawei.hwid.permission.gameservice.archive.access.provider
      </div>
      <div class="text scrollable">
        <table>
          <tr>
            <td>
              <h3>调用的设备权限</h3>
            </td>
            <td>
              <h3>权限对应的业务功能</h3>
            </td>
            <td>
              <h3>调用权限目的</h3>
            </td>
          </tr>
          <tr>
            <td>
              获取网络状态权限
            </td>
            <td>
              获取网络状态
            </td>
            <td>
              用于检测当前的网络连接是否有效。
            </td>
          </tr>
          <tr>
            <td>
              获取网络权限
            </td>
            <td>
              获取网络权限
            </td>
            <td>
              用于访问网络。
            </td>
          </tr>
          <tr>
            <td>
              判断当前前台应用的权限
            </td>
            <td>
              判断当前前台应用
            </td>
            <td>
              允许程序获取当前或最近运行任务的信息。
            </td>
          </tr>
          <tr>
            <td>
              展示悬浮窗的权限
            </td>
            <td>
              用于展示悬浮窗的权限
            </td>
            <td>
              用于用户在游戏中展示浮标。
            </td>
          </tr>
          <tr>
            <td>
              获取已安装应用列表的权限
            </td>
            <td>
              获取已安装应用列表
            </td>
            <td>
              用于检测用户是否在手机上安装了应用市场App和游戏中心App。
            </td>
          </tr>
          <tr>
            <td>
              获取网络状态权限
            </td>
            <td>
              获取网络状态
            </td>
            <td>
              用于检测当前的网络连接是否有效。
            </td>
          </tr>
          <tr>
            <td>
              获取Wi-Fi状态权限
            </td>
            <td>
              获取Wi-Fi状态
            </td>
            <td>
              用于获取当前Wi-Fi接入的状态以及WLAN热点的信息。
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
